.work-bl {
  align-items: stretch;
}
.work {
  width: 48.5%;
  margin-bottom: 30px;
}
.work h4 {
  margin: 0 0 30px;
  font-weight: 600;
  font-size: 26px;
}
.work h4 img {
  margin-right: 30px;
}
.work p {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;
}

@media screen and (min-width: 1440px) {
  .work {
    width: 48.5%;
    margin-bottom: 1.5em;
  }
  .work h4 {
    margin: 0 0 1em;
    font-size: 1.5em;
  }
  .work h4 img {
    margin-right: 1.2em;
    width: 2em;
  }
  .work p {
    font-size: 1.1em;
    line-height: 1.5em;
    margin-bottom: 1.2em;
  }
  .work-img {
    width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .work-bl {
    align-items: stretch;
    flex-direction: column;
  }
  .work {
    padding: 30px;
    width: 100%;
    margin-bottom: 20px;
  }
  .work h4 {
    margin: 0 0 20px;
    font-weight: 600;
    font-size: 18px;
  }
  .work h4 img {
    margin-right: 20px;
  }
  .work p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .work-bl {
    flex-direction: column-reverse;
  }
  .work-top {
    margin-top: -150px;
  }
}

@media screen and (max-width: 500px) {
  .work {
    padding: 0px;
  }

  .work-img {
    width: 90%;
  }
}
