.head-cont {
  padding: 130px 0 150px;
  color: #081250;
  background: url(../../assets/img/rec.svg) right top/35% no-repeat;
}
.head-cont .input {
  margin: 0 40px 0 0;
}
.head-text h1 {
  font-weight: 600;
  font-size: 38px;
  line-height: 48px;
  margin: 0 0 50px;
}
.head-text h1 + p {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}
.cont-image {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .cont-image {
    width: 50%;
  }
}
@media screen and (min-width: 1440px) {
  .head-cont {
    padding: 8em 0 10em;
    background: url(../../assets/img/rec.svg) right top/35% no-repeat;
  }
  .head-cont .input {
    margin: 0 2.3em 0 0;
  }
  .head-cont .image {
    width: 52%;
    flex-shrink: 0;
  }
  .head-text h1 {
    font-size: 2.4em;
    line-height: normal;
    margin: 0 0 1em;
  }
  .head-text h1 + p {
    font-weight: 500;
    font-size: 1.15em;
    line-height: 1.5em;
  }
  .cont-image {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .head-cont {
    padding: 90px 0 80px;
    color: #081250;
    background: url(../../assets/img/rec3.svg) right 450px no-repeat;
  }
  .head-cont .input {
    margin: 0;
  }
  .head-cont .visible-xs {
    width: 100%;
    margin-top: 30px;
  }
  .head-text h1 {
    font-weight: 600;
    font-size: 26px;
    line-height: 34px;
    margin: 0 0 30px;
  }
  .head-text h1 + p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 30px;
  }
  .head-cont .flex-s {
    align-items: normal;
  }
}
