.modal {
  height: 100%;
  width: 100%;
  background-color: rgba(8, 18, 80, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 60;
}
.modal .window {
  background-color: white;
  width: 540px;
  border-radius: 20px;
  padding: 44px 48px 68px 48px;
}

.modal .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 42px;
}
.modal .but {
  font-size: 18px;
  width: 349px;
}
.modal p {
  color: #081250;
  line-height: 26px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
@media screen and (max-width: 500px) {
  .modal .window {
    margin: 0px 15px;
    padding: 30px 15px 43px 15px;
  }

  .modal .content {
    gap: 30px;
  }
  .modal .but {
    width: 281px;
  }
  .modal p {
    font-size: 14px;
  }
}
