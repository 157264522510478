footer {
  border-top: 1px solid #b3b3b3;
  padding: 30px 0;
  font-size: 18px;
}
footer .mailto img {
  margin-right: 20px;
}
footer .tel {
  font-weight: 900;
  font-size: 18px;
  color: #007cb0;
  -webkit-text-stroke: 1px #121212;
  text-stroke: 1px #121212;
}
footer .box {
  justify-content: flex-start;
  gap: 60px;
  margin-left: 35px;
}
@media screen and (min-width: 990px) {
  footer .box {
    gap: 40px;
  }
}
@media screen and (min-width: 1440px) {
  footer {
    padding: 1.5em 0;
    font-size: 1.2em;
  }
  footer .soc a img {
    width: 2em;
  }
  footer .mailto img {
    margin-right: 1em;
  }
  footer .tel {
    font-size: 1.1em;
  }
  footer .box {
    gap: 80px;
  }
}
@media screen and (max-width: 990px) {
  footer .box {
    gap: 20px;
    margin-left: 0px;
    width: 100%;
    min-width: 500px;
  }
  footer > div {
    flex-direction: column;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  footer .logo {
    margin-bottom: 20px;
    margin-inline: auto;
  }
  footer .mailto {
    margin: 20px 0;
  }
  footer .mailto img {
    margin-right: 20px;
  }
  footer .soc {
    order: 1;
    margin-top: 15px;
  }
  footer .soc a {
    margin: 0 15px;
  }
  footer .box {
    justify-content: flex-start;
    gap: 0px;
    margin-left: 0px;
    max-width: 100%;
    min-width: 300px;
  }
}
