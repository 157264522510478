.pad-top {
  padding-top: 50px;
}
.pad-bot {
  padding-bottom: 50px;
}
.icons {
  position: relative;
}
.flex-b.icon-bl {
  align-items: stretch;
}
.icons .deco {
  position: absolute;
  left: 0;
  top: -280px;
  z-index: -1;
}
.icon-it {
  width: 23.5%;
  padding: 30px 20px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(37, 156, 165, 0.11);
  border-radius: 10px;
  margin-bottom: 20px;
}
.icon-it h4 {
  margin: 30px 0 10px;
  font-weight: 500;
  font-size: 18px;
}
.icon-it p {
  font-size: 14px;
  line-height: 22px;
}
@media screen and (min-width: 1440px) {
  .pad-top {
    padding-top: 3em;
  }
  .pad-bot {
    padding-bottom: 50px;
  }
  .icons {
    position: relative;
  }
  .flex-b.icon-bl {
    align-items: stretch;
  }
  .icons .deco {
    top: -15em;
    width: 22%;
  }
  .icon-it {
    width: 23.5%;
    padding: 2em 1.2em 1.2em;
    border-radius: 0.5em;
    margin-bottom: 1.2em;
  }
  .icon-it img {
    height: 3.2em;
  }
  .icon-it h4 {
    margin: 1.5em 0 0.6em;
    font-size: 1.15em;
  }
  .icon-it p {
    font-size: 0.9em;
    line-height: 1.55em;
  }
}
@media screen and (max-width: 900px) {
  .flex-b.icon-bl {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .icon-it {
    min-width: 250px;
  }
}
@media screen and (max-width: 768px) {
  .pad-top {
    padding-top: 30px;
  }
  .pad-bot {
    padding-bottom: 30px;
  }
  .icons {
    position: relative;
  }
  .icon-bl {
    align-items: stretch;
    flex-wrap: wrap;
  }

  .icon-it {
    width: 100%;
    padding: 30px 20px 20px;
  }
}
