.input {
  background: none;
  border: none;
  border-bottom: 1px solid #003791;
  outline: none;
  width: 240px;
  padding: 5px 0;
  font-weight: 500;
  font-size: 16px;
}
.tarif-container {
  position: relative;
}
.tarif-container .deco {
  position: absolute;
  right: 0px;
  z-index: -1;
  top: 35%;
}
.tarif-cont {
  align-items: flex-start;
}
.tarif-bl {
  flex-wrap: wrap;
}
.tarif-bl > div {
  width: 48%;
  height: 250px;
}
.tarif {
  /*   background: #FFFFFF;*/
  box-shadow: 0px 0px 20px rgba(37, 156, 165, 0.11);
  border-radius: 10px;
  padding: 45px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.tarif:after {
  background: url(../../assets/img/deco2.svg) center/100% 100% no-repeat;
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  bottom: -30%;
  z-index: -1;
}
.tarif-mod:after {
  background: url(../../assets/img/deco3.svg) center/100% 100% no-repeat;
}
.tarif h5 {
  font-size: 26px;
  font-weight: 400;
  margin-top: inherit;
  text-align: center;
}
.tarif p b {
  font-weight: bold;
  font-size: 20px;
  display: block;
  margin-bottom: 5px;
  color: #fff;
}
.tarif p span {
  color: #c7c7c7;
}
.tarif-mod p span {
  color: #fff;
}
.tarif_mod {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tarif_mod p b {
  color: #007cb0;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}
.tarif_mod p span {
  color: #a0a0a0;
}

.tarif-form {
  width: 445px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(37, 156, 165, 0.11);
  border-radius: 30px;
  padding: 50px 30px;
  text-align: center;
  flex-shrink: 0;
  margin-left: 30px;
  margin-bottom: 30px;
}
.tarif-form h4 {
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  margin: 0 0 40px;
}
.tarif-form .but {
  margin: 10px auto 30px;
}
.polit {
  font-size: 14px;
}
.aten {
  font-size: 20px;
  line-height: 1.45;
}
.tw {
  width: 40%;
  flex-shrink: 0;
  margin-left: 20px;
}
.tw > img {
  margin-right: 30px;
  width: 100px;
}
.tw p {
  margin-bottom: 20px;
  color: #fff;
}
.tw .but {
  width: 100%;
  background: #00b7bb;
}
@media screen and (min-width: 1440px) {
  .input {
    width: 15.5em;
    padding: 0.4em 0;
    font-size: 1.1vw;
  }
  .tarif-container {
    position: relative;
  }
  .tarif-container .deco {
    position: absolute;
    right: 0;
    z-index: -1;
    top: 45%;
    width: 38%;
  }
  .tarif-cont {
    align-items: flex-start;
  }
  .tarif-bl {
    flex-wrap: wrap;
  }
  .tarif-bl > div {
    width: 48%;
    height: 16em;
  }
  .tarif {
    border-radius: 0.5em;
    padding: 2.2em 1em 1em;
    margin-bottom: 1.5em;
  }
  .tarif h5 {
    font-size: 26px;
    font-weight: 400;
  }
  .tarif p b {
    font-size: 1.2em;
    margin-bottom: 0.3em;
  }
  .tarif_mod {
    margin-bottom: 1.7em;
  }
  .tarif_mod p b {
    font-size: 1.2em;
    line-height: 130%;
  }

  .tarif-form {
    width: 28em;
    border-radius: 1.5em;
    padding: 3em 2em;
    flex-shrink: 0;
    margin-left: 2em;
    margin-bottom: 2em;
  }
  .tarif-form h4 {
    font-size: 1.6em;
    line-height: normal;
    margin: 0 0 1.5em;
  }
  .tarif-form .but {
    margin: 0.5em auto 2em;
  }
  .polit {
    font-size: 0.85em;
  }
  .aten {
    font-size: 1.2em;
  }

  .tw {
    width: 40%;
    flex-shrink: 0;
    margin-left: 1em;
  }
  .tw > img {
    margin-right: 1.6em;
    width: 6em;
    flex-shrink: 0;
  }
  .tw p {
    margin-bottom: 1em;
  }
}
@media screen and (max-width: 1199px) {
  .tarif-form {
    margin-right: 0px;
  }
}
@media screen and (max-width: 990px) {
  .tarif-cont {
    flex-wrap: wrap;
    justify-content: center !important;
  }
}
@media screen and (max-width: 768px) {
  .input {
    width: 100%;
    padding: 5px 0;
  }
  .tarif-container {
    position: relative;
    padding-bottom: 100px;
  }
  .tarif-container .deco {
    position: absolute;
    z-index: -1;
    top: calc(100% - 430px);
    width: 100%;
  }
  .tarif-cont {
    align-items: flex-start;
    flex-direction: column;
  }
  .tarif-bl {
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
  }
  .tarif-bl > div {
    width: 100%;
    height: 255px;
  }
  .tarif {
    padding: 40px 20px 20px;
    margin-bottom: 20px;
  }
  .tarif:after {
    background: url(../../assets/img/deco2.svg) center 30px/100% 100% no-repeat;
  }
  .tarif-mod:after {
    background: url(../../assets/img/deco3.svg) center 30px/100% 100% no-repeat;
  }
  .tarif p span {
    color: #c7c7c7;
    font-size: 14px;
  }
  .tarif-mod p span {
    color: #fff;
  }
  .tarif h5 {
    text-align: left;
  }
  .tarif_mod {
    margin: 0 auto 30px;
    width: 80% !important;
  }

  .tarif-form {
    width: 100%;
    background: #ffffff;
    padding: 50px 30px;
    text-align: center;
    flex-shrink: 0;
    margin-left: 0px;
    margin-bottom: 30px;
  }
  .tarif-form h4 {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    margin: 0 0 40px;
  }
  .tarif-form .but {
    margin: 10px auto 30px;
  }
  .polit {
    font-size: 12px;
  }
  .aten-bl {
    flex-direction: column;
  }
  .aten {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.42;
  }

  .tw {
    width: 100%;
    flex-shrink: 0;
    margin-left: 0px;
    flex-direction: column;
  }
  .tw > img {
    margin-right: 0px;
    width: 132px;
  }
  .tw p {
    margin-bottom: 20px;
    color: #fff;
    text-align: center;
    margin-top: 20px;
    line-height: 28px;
  }
  .tw .but {
    width: 90%;
    margin: auto;
  }
}
