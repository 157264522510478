.container.head {
  padding: 20px 15px;
  transition: all linear 0.15s;
  margin-left: 20px;
  max-width: 1250px;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  transition: all linear 0.2s;
}
.style.fixed {
  background: #fff;
}
.head nav a {
  margin: 0 15px;
}
.head .mailto {
  font-weight: 500;
  font-size: 18px;
  color: #007cb0;
}
.head .tel {
  font-weight: 900;
  font-size: 18px;
  color: #fff;
  display: block;
  text-align: right;
}
.head .shown {
  display: block;
}
.head .hidden {
  display: none;
}
.nav-control {
  display: none;
}
.but.but_mod {
  background: none;
  color: #081250;
  font-weight: 500;
  width: 200px;
  border: 1px solid #fb5154;
  height: 50px;
}
.but.but_active {
  color: #fff;
  background: #fb5154;
}
.logo {
  width: 228px;
}
.head .but {
  margin-right: 30px;
}
.style .head .tel {
  color: #003791;
}
@media screen and (min-width: 1440px) {
  .container.head {
    padding: 20px 10px;
    margin-left: auto;
    max-width: inherit;
  }
  .head .logo {
    width: 14.5em;
    margin-left: -100px;
  }
  .style .head {
    padding: 0.6em 15px;
  }
  .head nav a {
    margin: 0 1em;
  }
  .head .mailto {
    font-size: 1.15em;
  }
  .head .tel {
    font-size: 1.15em;
  }
  .but.but_mod {
    background: none;
    color: #081250;
    font-weight: 500;
    border: 1px solid #fb5154;
    width: 15.5em;
    height: 3.5em;
  }
  .but.but_active {
    color: #fff;
    background: #fb5154;
  }
}
@media screen and (max-width: 940px) {
  .container.head {
    padding: 20px 10px;
    margin-left: auto;
    width: 100%;
    min-width: 500px;
    background-color: #ffffff;
  }
  .style .head {
    padding: 10px 15px;
  }
  .head nav {
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    background: #fff;
    flex-direction: column;
    top: 55px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  }
  .head nav.burger {
    display: flex;
  }
  .head nav a {
    margin: 15px;
    display: flex;
  }
  .head .mailto {
    display: none;
  }
  .head .tel {
    font-weight: 600;
    font-size: 16px;
    color: #003791;
  }
  .nav-control {
    display: block;
    cursor: pointer;
  }
  .but.but_mod {
    display: none;
  }
  .head .shown {
    display: none;
  }
  .head .hidden {
    display: block;
  }
  .head .box {
    margin-inline: auto;
  }
}

@media screen and (max-width: 768px) {
  .container.head {
    max-width: inherit;
    margin-left: 0px;
    min-width: 300px;
  }
  .head {
    padding: 20px 15px;
    transition: all linear 0.15s;
  }
  .head .logo {
    width: 228px;
    margin-left: 0px;
  }
  .head nav {
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    background: #fff;
    flex-direction: column;
    top: 55px;
  }
  .head nav.burger {
    display: flex;
  }
  .head nav a {
    margin: 15px;
  }
  .head .mailto {
    display: none;
  }
  .head .tel {
    font-weight: 600;
    font-size: 16px;
    color: #003791;
  }
  .nav-control {
    display: block;
    cursor: pointer;
  }
  .but.but_mod {
    display: none;
  }
  .head .box {
    margin-inline: initial;
  }
}
@media screen and (max-width: 460px) {
  .head .logo {
    width: 110px;
  }
}
