.part-cont {
  position: relative;
  margin-bottom: 50px;
  margin-left: 0px;
  margin-right: 0px;
}
.part-cont .deco {
  position: absolute;
  left: 0;
  top: -90%;
  z-index: -1;
}
.partner {
  margin-left: 125px;
}
.partner p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 1.4;
}
.partner .mailto {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #003791;
}
.partner .tel {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #00b7bb;
  margin: 20px 0 30px;
  display: block;
}

@media screen and (min-width: 1440px) {
  .part-cont {
    position: relative;
    margin-bottom: 3em;
    margin-left: 160px;
    margin-right: 0px;
  }
  .part-cont .deco {
    position: absolute;
    left: 0;
    top: -90%;
    z-index: -1;
    width: 42%;
  }
  .partner {
    margin-left: 7.8em;
  }
  .part-img {
    width: 30%;
    flex-shrink: 0;
  }
  .partner p {
    font-size: 1.2em;
    margin-bottom: 1em;
  }
  .partner .mailto {
    font-size: 1.2em;
  }
  .partner .tel {
    font-size: 1.2em;
    line-height: normal;
    margin: 1em 0 1.5em;
  }
}

@media screen and (max-width: 768px) {
  .part-cont {
    position: relative;
    margin-bottom: 50px;
  }
  .part-cont .deco {
    position: absolute;
    left: 0;
    top: -83%;
    z-index: -1;
    width: 87%;
  }
  .part-bl {
    flex-direction: column;
  }
  .part-img {
    width: 60%;
  }
  .partner {
    margin-left: 0px;
    padding-right: 0px;
    margin-top: 20px;
  }
  .partner p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 650px) {
  .part-cont .deco {
    top: -60%;
  }
}
@media screen and (max-width: 500px) {
  .part-cont .deco {
    top: -42%;
    width: 95%;
  }
}
