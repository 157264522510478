body {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: Montserrat;
  font-weight: 400;
  src: url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: url(./assets/fonts/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 600;
  src: url(./assets/fonts/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: url(./assets/fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 900;
  src: url(./assets/fonts/Montserrat-Black.ttf) format('truetype');
}

* {
  outline: none;
  box-sizing: border-box;
}
*::-webkit-input-placeholder {
  color: #767676;
  opacity: 0.8;
}
*:-moz-placeholder {
  color: #767676;
  opacity: 0.8;
}
*::-moz-placeholder {
  color: #767676;
  opacity: 0.8;
}
*:-ms-input-placeholder {
  color: #767676;
  opacity: 0.8;
}

.container:after,
.container:before {
  display: none;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #121212;
  font-weight: normal;
}

a {
  color: #121212;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  background: transparent;
}
a:focus {
  text-decoration: none;
}
p {
  margin: 0;
}

a.add-hov:hover {
  color: #081250;
}
input {
  font-family: inherit;
}
.modal-open {
  overflow: hidden;
}
.block {
  padding: 60px 0;
}
.container {
  padding: 0 15px;
  max-width: 1130px;
  margin-right: auto;
  margin-left: auto;
}
.block.container {
  padding: 50px 15px;
}

.flex-b {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-s {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.headline {
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: #000000;
  margin: 0 0 50px;
}
.but {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 240px;
  height: 55px;
  color: #fff;
  background: #fb5154;
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
}
.soc a {
  margin-right: 25px;
}
.visible-xs {
  display: none;
}
.hidden-xs {
  display: flex;
}
/* LOADER */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@media screen and (min-width: 1440px) {
  body {
    font-size: 1.1vw;
  }
  .block {
    padding: 3em 0;
  }
  .container {
    padding: 0 15px;
    max-width: 79%;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
  .block.container {
    padding: 3em 15px;
  }
  .headline {
    font-size: 2.3em;
    margin: 0 0 1.3em;
  }
  .but {
    width: 15.5em;
    height: 3.5em;
    font-size: 1.1vw;
  }
  .soc a {
    margin-right: 1.5em;
  }
  .soc a img {
    width: 3em;
  }
}
@media screen and (max-width: 1199px) {
  .container {
    min-width: 950px;
    width: 80%;
  }
  .flex-p {
    gap: 15px;
  }
}

@media screen and (max-width: 991px) {
  body {
    overflow-x: hidden;
  }

  .container {
    min-width: 750px;
  }
}
@media screen and (max-width: 768px) {
  body {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
  }

  .block {
    padding: 30px 0;
  }
  .container {
    padding: 0 15px;
    max-width: 100%;
    min-width: 350px;
    width: 100%;
    max-width: 500px;
  }
  .block.container {
    padding: 30px 15px;
  }
  .headline {
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    margin: 0 0 30px;
  }
  .but {
    width: 100%;
    height: 55px;
  }
  .head-form {
    display: flex;
    flex-direction: column;
  }
  .visible-xs {
    display: flex;
  }
  .hidden-xs {
    display: none;
  }
}

.error-field {
  display: block;
  color: #fb5154;
  font-size: 14px;
}
